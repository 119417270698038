import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#265AB1',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontWeight: 'bold',
    },
    stockNumber: {
        width: '15%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    description: {
        width: '10%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    amount: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    cost: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const InvoiceTableRow = ({items}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const entityType = urlParams.get('type');
    const rows = items.map( item =>
        <View style={styles.row} key={item.vehicleId}>
            <Text style={styles.stockNumber}>{item.dealerStockNumber ? item.dealerStockNumber : ' '}</Text>
            <Text style={styles.description}>{item.year}</Text>
            <Text style={styles.qty}>{item.make}</Text>
            <Text style={styles.rate}>{item.model}</Text>
            <Text style={styles.amount}>{item.vin.toString()}</Text>
            {
                entityType === '1' ?
                <Text style={styles.cost}>${item.quote.dealerPrice}</Text>
                :
                <Text style={styles.cost}>${item.quote.transporterPrice}</Text>
            }
        </View>
    )
    return (<Fragment>{rows}</Fragment> )

    // return (
    //   <Fragment>
    //   <View style={styles.row}>
    //           <Text style={styles.description}>Hello</Text>
    //           <Text style={styles.qty}>World</Text>
    //           <Text style={styles.rate}>Test</Text>
    //           <Text style={styles.amount}>Fragment</Text>
    //           <Text style={styles.cost}>$100</Text>
    //       </View>
    //       </Fragment>
    // )
};

export default InvoiceTableRow
