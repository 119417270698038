import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000000'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#265AB1',
        backgroundColor: '#265AB1',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontWeight: 'bold',
        flexGrow: 1,
        color: '#FFFFFF'
    },
    stockNumber: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    year: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    make: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    model: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    vin: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    cost: {
      width: '15%'
    }

  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.stockNumber}>Stock #</Text>
        <Text style={styles.year}>Year</Text>
        <Text style={styles.make}>Make</Text>
        <Text style={styles.model}>Model</Text>
        <Text style={styles.vin}>Vin</Text>
        <Text style = {styles.cost}>Amount</Text>
    </View>
  );

  export default InvoiceTableHeader
