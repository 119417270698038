import './App.less';
import Invoice from './Invoice'
import {Spin} from 'antd';
import { fetchShipment } from './api';
import { PDFViewer } from '@react-pdf/renderer';
import React, { Component} from 'react';

class App extends Component {



  state = {
    shipmentData: [],
    loading: true
  }

  componentDidMount = () =>{
    const urlParams = new URLSearchParams(window.location.search);
    const shipmentId = urlParams.get('shipmentId');
    if (!shipmentId) {
      window.alert('Shipment Id missing');
    }
    else{
      fetchShipment(shipmentId).then(data => this.setState({shipmentData: data, loading: false}));
    }

    // fetchShipment('3263dab7-cc38-4b7f-96d8-647a4f5fd184').then(data => this.setState({shipmentData: data, loading: false}));
    // fetchShipment('3263dab7-cc38-4b7f-96d8-647a4f5fd184').then(data => console.log(JSON.stringify(data,null,2)));
    // console.log(JSON.stringify(this.state.shipmentData,null,2));
  }
  

  render () {

    const { shipmentData, loading } = this.state;
    return (
      <div>
        {loading ?
          <div
            style={{
              textAlign: 'center',
              borderRadius: '4px',
              marginBottom: '20px',
              padding: '30px 50px',
              margin: '20px 0'
            }}
          >
            <Spin />
          </div>
          :
          <PDFViewer width={window.innerWidth } height={window.innerHeight} className={"3263dab7"}>
            <Invoice invoice={shipmentData}></Invoice>
          </PDFViewer>
  }
      </div>
    );
  }
  
}

export default App;
