import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: 'row',
    marginTop: 36,
    justifyContent: 'flex-end'
  },
  invoiceDateContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  invoiceBillingAddress: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: 'bold',
    justifyContent: 'flex-end'
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column'
  },
  bill: {
    fontWeight: 'bold'
  },
  billText: {
    paddingLeft: 42,
  },
  shipText: {
    paddingLeft: 25,
  },
  label: {
    justifyContent: 'flex-end'
  }

});

const InvoiceNo = ({invoice}) => (
  <Fragment>


    <View style={styles.row}>
      <View style={styles.column}>
        <View style={{flexDirection:'row'}}>
          <Text  style={{fontFamily:'Helvetica-Bold'}}> Pickup:</Text>
          <Text style={{maxWidth: '150px'}}> {invoice.pickup.location.name}</Text>
        </View>
        <Text style={styles.billText}>{invoice.pickup.location.addressline1}</Text>
        <Text style={styles.billText}>{invoice.pickup.location.city}, {invoice.pickup.location.state}, {invoice.pickup.location.zip}</Text>
      </View>

      {/* eslint-disable-next-line */}
      <View style={styles.column, {paddingLeft: 80}}>
        <View style={{flexDirection:'row'}}>
          <Text style={{fontFamily:'Helvetica-Bold'}}>Ship To: </Text>
          <Text style={{maxWidth: '175px'}}> {invoice.dropoff.location.name}</Text>
        </View>
        <Text style={{paddingLeft:45}}>{invoice.dropoff.location.addressline1}</Text>
        <Text style={{paddingLeft:45}}>{invoice.dropoff.location.city}, {invoice.dropoff.location.state}, {invoice.dropoff.location.zip}</Text>
      </View>
    </View>


  </Fragment>
);


export default InvoiceNo
