import React from 'react';
import moment from 'moment-timezone';

//PDF imports
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
// import InvoiceTitle from './InvoiceTitle'
import logo from './autosled_logo.png'
import InvoiceNo from './InvoiceNumber'
import InvoiceItemsTable from './InvoiceItemsTable'

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dateRow: {
    flexDirection: 'row',
    textAlign: 'right',
    justifyContent: 'flex-end'
  },
  balanceRow: {
    flexDirection: 'row',
    textAlign: 'right',
    justifyContent: 'flex-end',

  },
  title: {
    color: '#0f0f0f',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    fontFamily:'Times-Bold',
    fontSize:25
  },
  column: {
    flexDirection: 'column'
  },
  logo: {
    width: 100,
    height: 100,
  }
});

const urlParams = new URLSearchParams(window.location.search);
const entityType = urlParams.get('type');

//invoice should return a document
const Invoice = ({invoice}) => (
  <Document>
    <Page size="A4" style={styles.page}>



      <View style={styles.row}>

        <View style={[styles.column,{justifyContent:'flex-start'},{marginTop:15}]}>
          <Text style={styles.title}>Invoice </Text>
            
            {
          entityType === "1" 
          ?
          <View style={[{flexDirection:'row'},{fontSize:15}]}>
            <Text style={{fontFamily:'Helvetica-Bold'}}> Shipment ID: </Text>
              <Text>{invoice.shipmentId.split('-')[0]}</Text>
              </View>
              :
              <View style={[{flexDirection:'row'},{fontSize:15}]}>
            <Text style={{fontFamily:'Helvetica-Bold'}}> Load ID: </Text>
              <Text>{invoice.loadId.split('-')[0]}</Text>
              </View>
            }


              
              


            

        </View>



          <Image style={[styles.logo,{alignSelf:'flex-start'},{marginLeft:'auto'}]} src={logo} />

            <View style={[styles.column,{paddingLeft:15},{alignItems:'end'},{marginTop:15}]}>
              <Text> AUTOSLED</Text>
              <Text> 6010 Executive Blvd</Text>
              <Text> Rockville, MD, 20852</Text>
              <Text> 240-880-2711</Text>
            </View>
      </View>






      <View style={[styles.row, {paddingBottom:50},{paddingTop:20}]}>

        {
          entityType === "1"  
          ?
          <View style={styles.column}>
          <View style={{flexDirection:'row'}}>
              <Text style={{fontFamily:'Helvetica-Bold'}}>Bill To: </Text>
              <Text style={{fontWeight:'800'}}> {invoice.customer.name}</Text>
          </View>
          <Text style={{paddingLeft:37}}>{invoice.customer.location.addressLine1}</Text>
          <Text style={{paddingLeft:37}}>{invoice.customer.location.city}, {invoice.customer.location.state}, {invoice.customer.location.zip}</Text>
        </View>
        :
        <View style={styles.column}>
          <View style={{flexDirection:'row'}}>
              <Text style={{fontFamily:'Helvetica-Bold'}}>Pay To: </Text>
              <Text style={{fontWeight:'800', maxWidth: '200px'}}> {invoice.transporter.name}</Text>
          </View>
          <Text style={{paddingLeft:37}}>{invoice.transporter.location.addressLine1}</Text>
          <Text style={{paddingLeft:37}}>{invoice.transporter.location.city}, {invoice.transporter.location.state}, {invoice.transporter.location.zip}</Text>
        </View>
        }

        <View style={[styles.column, {paddingLeft: 60}]}>
          <View style={[{flexDirection:'row'}, {justifyContent:'space-between'}]}>
            <View style={{flexDirection:'column'}}>
              <Text style={{paddingLeft:40}}>Date:</Text>
            </View>
            <View style={{flexDirection:'column'}}>
              <Text>{moment(invoice.updatedAt).format('MM/DD/YYYY')}</Text>
            </View>
          </View>

          <View style={[{flexDirection:'row'}, {justifyContent:'space-between'}]}>
            <View style={{flexDirection:'column'}}>
              <Text style={{paddingLeft:17}}>Due Date:</Text>
            </View>
            <View style={{flexDirection:'column'}}>
              <Text>{moment(invoice.updatedAt).add(7,'days').format('MM/DD/YYYY')}</Text>
            </View>
          </View>

          <View style={[{flexDirection:'row'}, {justifyContent:'space-between'}, {backgroundColor:'#C0C0C0'}]}>
            {/* eslint-disable-next-line */}
            <View style={{flexDirection:'column'},{paddingRight:50}}>
              <Text style={{paddingRight:60}}>Balance Due:</Text>
            </View>
            <View style={{flexDirection:'column'}}>
              {entityType === '1'
              ?
              <Text>${invoice.vehicles.map(item => item.quote.dealerPrice).reduce((accumulator, currentValue) => accumulator + currentValue , 0)}</Text>
              :
              <Text>${invoice.vehicles.map(item => item.quote.transporterPrice).reduce((accumulator, currentValue) => accumulator + currentValue , 0)}</Text>
              }
            </View>
          </View>

        </View>

      </View>

      <InvoiceNo invoice={invoice}/>
      <InvoiceItemsTable invoice={invoice} />

      {/* eslint-disable-next-line */}
      <Text style={{flexDirection:'row'},{marginTop:'auto'}}>Terms </Text>
      <Text style={{flexDirection:'row'}}>Payment is due in seven days from the invoice date. If you need any assistance with payment processing please contact Autosleds email: payments@autosled.com or phone: 240-880-2711</Text>

      <Text style={{paddingTop:20}}>Thank you for your business</Text>
      <Text style={{marginBottom:'45px'}}> -Autosled</Text>
  </Page>
  </Document>
);


export default Invoice
